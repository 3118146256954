/**
 * Created by methee on 8/4/17.
 */
/* eslint-disable css-modules/no-unused-class */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, defineMessages, intlShape } from 'react-intl';
import cx from 'classnames';

import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Remembered.css';

import RememberedCard from './RememberedCard';
import iconHandGesture from './icon-hand-gesture.webp';
import iconHandGestureAdd2x from './icon-hand-gesture@2x.webp';

const messages = defineMessages({
  remembered: {
    id: 'homepage.remembered',
    defaultMessage: '##Remembe{red}##',
    description:
      'Remembered header in homepage. Do not remove or translate the {red}. Please only translate "Remembe". The "red" path will get translated separately',
  },
  rememberedRed: {
    id: 'homepage.remembered.red',
    defaultMessage: '##red##',
    description: 'The "red" path of "Remembered" title header in homepage.',
  },
  description: {
    id: 'homepage.benefits_description',
    defaultMessage:
      'Below are amazing benefits that come with being a {remembered} member',
    description:
      'Description for remembered section on homepage. Do not remove or translate {remembered}',
  },
  tenPercentOff: {
    id: 'homepage.benefit_10off_title',
    defaultMessage: '##10% off lifetime##',
    description: 'Remembered benefits under photo',
  },
  twentyFivePercentBirthday: {
    id: 'homepage.benefit_birthday_title',
    defaultMessage: '##25% on your Birthday##',
    description: 'Remembered benefits under photo',
  },
  diningBenefits: {
    id: 'homepage.benefit_dining_title',
    defaultMessage: '##Dining Benefits##',
    description: 'Remembered benefits under photo',
  },
  tenPlusOne: {
    id: 'homepage.ten_plus_one_title',
    defaultMessage: '##10 nights, 1 free##',
    description: 'Remembered benefits under photo',
  },
  inStayMode: {
    id: 'homepage.benefit_in_stay_title',
    defaultMessage: '##In-Stay Mode##',
    description: 'Remembered benefits under photo',
  },
  tenPercentOffMessage: {
    id: 'homepage.benefit_10off_description',
    defaultMessage:
      'Remembered members get 10% off our lowest online rate, all the time, at every hotel',
    description: 'Remembered benefits under photo',
  },
  twentyFivePercentBirthdayMessage: {
    id: 'homepage.benefit_birthday_description',
    defaultMessage:
      'Take 25% off when you book and stay with us during your birth month.',
    description: 'Remembered benefits under photo',
  },
  diningBenefitsMessage: {
    id: 'homepage.benefit_dining_description',
    defaultMessage:
      'Turn your phone sideways to show your virtual Remembered card and receive discounts and special offers at over 200+ venues.',
    description: 'Remembered benefits under photo',
  },
  tenPlusOneMessage: {
    id: 'homepage.ten_plus_one_description',
    defaultMessage: '##10 nights, 1 free##',
    description: 'Remembered benefits under photo',
  },
  inStayModeMessage: {
    id: 'homepage.benefit_in_stay_description',
    defaultMessage:
      'Turn your phone sideways to show your virtual Remembered card and receive discounts and special offers at over 200+ venues.',
    description: 'Remembered benefits under photo',
  },
  memberRate: {
    id: 'homepage.memberRateTitle',
    defaultMessage: '##Instant 10% off##',
    description: 'Remembered benefits under photo',
  },
  birthdayDiscount: {
    id: 'homepage.birthdayDiscountTitle',
    defaultMessage: '##25% birthday discount##',
    description: 'Remembered benefits under photo',
  },
  onlineCheckIn: {
    id: 'homepage.onlineCheckInTitle',
    defaultMessage: '##Online check-in##',
    description: 'Remembered benefits under photo',
  },
  appFeatures: {
    id: 'homepage.appFeaturesTitle',
    defaultMessage: '##App features##',
    description: 'Remembered benefits under photo',
  },
  restaurantOffers: {
    id: 'homepage.restaurantOffersTitle',
    defaultMessage: '##Restaurant offers##',
    description: 'Remembered benefits under photo',
  },
  memberRateMessage: {
    id: 'homepage.memberRateMessage',
    defaultMessage:
      '##Remembered members take 10% off all rooms at all hotels, every day.##',
    description: 'Remembered benefits under photo',
  },
  birthdayDiscountMessage: {
    id: 'homepage.birthdayDiscountMessage',
    defaultMessage:
      '##Toast your birthday with a 25% discount on all stays in your birth month.##',
    description: 'Remembered benefits under photo',
  },
  onlineCheckInMessage: {
    id: 'homepage.onlineCheckInMessage',
    defaultMessage:
      '##Check in online, and skip the paperwork. Your room keys will be waiting at the front desk.##',
    description: 'Remembered benefits under photo',
  },
  appFeaturesMessage: {
    id: 'homepage.appFeaturesMessage',
    defaultMessage:
      '##Unlock exclusive app features for a more personalised stay, from the moment you make a reservation until you check out.##',
    description: 'Remembered benefits under photo',
  },
  restaurantOffersMessage: {
    id: 'homepage.restaurantOffersMessage',
    defaultMessage:
      '##Get discounts at over 200 handpicked restaurants. Turn your phone sideways while in the app to show your membership card.##',
    description: 'Remembered benefits under photo',
  },
});

// Used png instead adding changes on the code since file sizes for webp and png are not that different in size
const benefitsImages = {
  tenPercentOff: {
    normal: 'tenPercentOff.png',
    add2x: 'tenPercentOff@2x.png',
  },
  twentyFivePercentBirthday: {
    normal: 'twentyFivePercentBirthday.png',
    add2x: 'twentyFivePercentBirthday@2x.png',
  },
  diningBenefits: {
    normal: 'diningBenefits.png',
    add2x: 'diningBenefits@2x.png',
  },
  tenPlusOne: {
    normal: 'tenPlusOne.png',
    add2x: 'tenPlusOne@2x.png',
  },
  inStayMode: {
    normal: 'inStayMode.png',
    add2x: 'inStayMode@2x.png',
  },
  memberRate: {
    normal: 'member-rate.png',
    add2x: 'member-rate@2x.png',
  },
  birthdayDiscount: {
    normal: 'birthday-discount.png',
    add2x: 'birthday-discount@2x.png',
  },
  onlineCheckIn: {
    normal: 'online-check-in.png',
    add2x: 'online-check-in@2x.png',
  },
  appFeatures: {
    normal: 'app-features.png',
    add2x: 'app-features@2x.png',
  },
  restaurantOffers: {
    normal: 'restaurant-offers.png',
    add2x: 'restaurant-offers@2x.png',
  },
};

const benefits = [
  'memberRate',
  'birthdayDiscount',
  // 'onlineCheckIn',
  'appFeatures',
  'restaurantOffers',
];

const elementHeight = 837;
// const maxWidth = 1600;
const handGestureWidth = 171;
const handGestureHeight = 152;

let initialScrollLeftPosition = 0;
let alreadyScrolled = false;

class Remembered extends React.Component {
  bindOnWindowResize = null;

  bindOnWindowScroll = null;

  constructor(props) {
    super(props);
    this.state = {
      handGesturePosition: { x: 0, y: 0 },
      showHandGesture: false,
    };
  }

  // eslint-disable-next-line camelcase
  componentDidMount() {
    if (process.env.BROWSER) {
      this.bindOnWindowResize = this.onWindowResize.bind(this);
      this.bindOnWindowScroll = this.onWindowScroll.bind(this);

      window.addEventListener('resize', this.bindOnWindowResize);
      window.addEventListener('scroll', this.bindOnWindowScroll);

      this.onWindowResize();
      // const middleOfContent = maxWidth / 2;
      // const middleOfViewPort = window.innerWidth / 2;
      // const leftScrollOffset = middleOfContent - middleOfViewPort;
      if (this.mask) {
        this.mask.scrollLeft = 0;
      }
      initialScrollLeftPosition = 0;
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.bindOnWindowResize);
    window.removeEventListener('scroll', this.bindOnWindowScroll);
    this.mask.removeEventListener('scroll', this.bindOnWindowScroll);
  }

  onWindowResize = () => {
    if (process.env.BROWSER) {
      const viewPortWidth = window.innerWidth;
      const viewPortHeight = window.innerHeight;
      const horizontalCenterOfViewPort = viewPortWidth / 2;
      const verticalCenterOfViewPort = viewPortHeight / 2;
      const horizontalCenterOfHandGesture = handGestureWidth / 2;
      const verticalCenterOfHandGesture = handGestureHeight / 2;
      this.setState({
        handGesturePosition: {
          x: horizontalCenterOfViewPort - horizontalCenterOfHandGesture,
          y: verticalCenterOfViewPort - verticalCenterOfHandGesture,
        },
      });
    }
  };

  onWindowScroll = () => {
    if (alreadyScrolled) {
      this.setState({ showHandGesture: false });
    } else if (
      typeof document !== 'undefined' &&
      typeof window !== 'undefined' &&
      this.mask &&
      this.root
    ) {
      const topOfElement = this.root.offsetTop;
      const leftScrolled =
        Math.abs(this.mask.scrollLeft - initialScrollLeftPosition) > 1;
      this.setState({
        showHandGesture:
          window.scrollY >= topOfElement &&
          window.scrollY <= topOfElement + elementHeight / 2 &&
          !leftScrolled,
      });
      alreadyScrolled = leftScrolled;
    }
  };

  render() {
    const { isMobileDevice } = this.props;
    const {
      handGesturePosition: { x, y },
      showHandGesture,
    } = this.state;
    const items = [];
    benefits.forEach(benefit => {
      const image = benefitsImages[benefit];
      const title = messages[benefit];
      const message = messages[`${benefit}Message`];
      items.push(
        <li key={benefit}>
          <RememberedCard
            image={{ ...image }}
            title={{ ...title }}
            benefits={{ ...message }}
            width="217px"
            height="356px"
          />
        </li>,
      );
    });
    return (
      <div
        ref={root => {
          this.root = root;
        }}
        className={cx(s.root, s[this.context.intl.locale])}
      >
        <h3>
          <FormattedMessage
            {...messages.remembered}
            values={{
              red: (
                <span className={s.highlight}>
                  <FormattedMessage {...messages.rememberedRed} />
                </span>
              ),
            }}
          />
        </h3>
        <p>
          <FormattedMessage
            {...messages.description}
            values={{
              remembered: (
                <span className={s.uppercase}>
                  <FormattedMessage
                    {...messages.remembered}
                    values={{
                      red: <FormattedMessage {...messages.rememberedRed} />,
                    }}
                  />
                </span>
              ),
            }}
          />
        </p>
        <div
          ref={mask => {
            this.mask = mask;
          }}
          onScroll={this.onWindowScroll}
          className={s.scrollMask}
        >
          <ul>{items}</ul>
        </div>
        {isMobileDevice && showHandGesture ? (
          <div
            id="rememberedIconHandGesture"
            className={s.iconHandGesture}
            style={{ left: x, top: y }}
          >
            <img
              src={iconHandGesture}
              srcSet={`${iconHandGestureAdd2x} 2x`}
              alt="ico hand gesture"
            />
          </div>
        ) : (
          ''
        )}
        <div className={s.footer} />
      </div>
    );
  }
}

Remembered.propTypes = {
  isMobileDevice: PropTypes.bool.isRequired,
};

Remembered.contextTypes = {
  intl: intlShape,
};

export default withStyles(s)(Remembered);
