/* eslint-disable react/prop-types */
/* eslint-disable react/static-property-placement */
import React from 'react';
// import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { defineMessages, FormattedMessage } from 'react-intl';
import cx from 'classnames';
import s from './CookiePolicy.css';

// import iconArrowDownAdd1x from './icon-arrow-down@1x.webp';
// import iconArrowDownAdd2x from './icon-arrow-down@2x.webp';

const messages = defineMessages({
  header: {
    id: 'cookiePolicy.header',
    defaultMessage: '##Cookie Policy##',
    description: 'cookie policy description',
  },
});

class CookiePolicy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      viewMore: false,
      allowCookie: false,
    };
  }

  handleChange() {
    this.setState(state => ({
      viewMore: !state.viewMore,
    }));
    // console.log(this.state.viewMore);
  }

  handleAllowCookie() {
    window.localStorage.setItem('allow_cookie', true);
    this.setState({ allowCookie: true });
    // console.log('allow_cookie');
  }

  render() {
    const { viewMore, allowCookie } = this.state;
    if (!allowCookie) {
      return (
        <div className={cx(s.root, viewMore && s.h100)}>
          <div className={cx(s.container, viewMore && s.h100)}>
            {/* <button
              type="button"
              onClick={() => this.handleChange()}
              className={s.arrowDown}
              hidden={!viewMore}
            >
              test */}
            {/* <i>
                <img
                  src={iconArrowDownAdd1x}
                  srcSet={`${iconArrowDownAdd2x} 2x`}
                  alt="arrow-down"
                />
              </i> */}
            {/* </button> */}
            {/* small view */}
            <div
              className={cx(
                s.content,
                viewMore && s.fullContent,
                viewMore && s.h100,
              )}
            >
              <div className={cx(s.left, viewMore && s.w100)}>
                <div className={s.header}>
                  <b>
                    <FormattedMessage {...messages.header} />
                  </b>
                </div>
                <div>
                  <p>
                    This site/app uses cookies. By continuing to browse our
                    site, you consent to their use. Please visit our cookie
                    policy to find out which cookies we use and why.
                    <button
                      type="button"
                      onClick={() => this.handleChange()}
                      className={s.btnViewMore}
                    >
                      {viewMore ? 'Less Cookie Policy.' : '...more.'}
                    </button>
                  </p>
                  <div hidden={!viewMore}>
                    <p>
                      Red Planet Philippines Corporation (“Red Planet”) uses
                      cookies on www.redplanethotels.com and its various
                      subdomains. By using our website, you consent to the use
                      of cookies.
                    </p>
                    <h3>What are cookies?</h3>
                    <p>
                      Cookies are little text files that are stored on the
                      browser of your computer or mobile device when you visit
                      our site. This enables our website to remember your
                      actions and preferences (e.g. your login credentials, text
                      fields you have completed, online shopping cart items,
                      etc.) over a period of time, so you do not have to keep
                      re-entering them whenever you come back to our site or
                      browse from one page to another.
                    </p>
                    <h3>How do we use cookies?</h3>
                    <p>
                      We use cookies for advertising and analytics purposes to
                      give you optimal experience when using our site, and to
                      serve you with content and advertisements that are
                      tailored to your interest and behavior. These cookies will
                      not be used for any other purpose other than to what are
                      stated in this policy.
                    </p>
                    <p>
                      Please read our{' '}
                      <a
                        href="/policy"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Privacy Policy
                      </a>{' '}
                      to learn more how we value your privacy.
                    </p>
                    <p>The types of cookies we use are:</p>
                    <ul>
                      <li>
                        Third-party cookies. These are installed by third
                        parties to report usage statistics, and deliver
                        advertisements, among others. These cookies are
                        anonymous, do not store personally identifiable
                        information, and store only user behavior on our
                        website.
                      </li>
                      <li>
                        Session cookies. These allow our website to keep items
                        in your cart when you shop in our online store. These
                        cookies expire when the browser is closed.
                      </li>
                    </ul>
                    <p>
                      All data collected by our website via cookies and other
                      tracking technologies are strictly controlled and
                      monitored by both Red Planet and our partner agencies. By
                      using our websites, you are consenting to our use of
                      cookies in accordance with this cookie policy and our{' '}
                      <a
                        href="/policy"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Privacy Policy
                      </a>
                      .
                    </p>
                    <p>
                      To change the cookie settings, you will need to manually
                      change the settings on your browser. Please visit the Help
                      page of your browser, for assistance. If you choose to
                      block these cookies, our website may not work as it should
                      and any preferences you have may be lost.
                    </p>
                  </div>
                </div>
              </div>
              <div className={cx(s.right, viewMore && s.w100)}>
                <div className={s.btn}>
                  <button
                    type="button"
                    className={cx(s.agree, viewMore && s.h48px)}
                    onClick={() => this.handleAllowCookie()}
                  >
                    Agree
                  </button>
                </div>
              </div>
              {/* <div className={s.header}>
                <h4>Cookie Policy</h4>
              </div>
              <div>
                <div className={s.textContent}>
                  This site/app uses cookies. By continuing to browse our site,
                  you consent to their use. Please visit our cookie policy to find
                  which cookies we use and why. <b>View Cookie Policy</b>.
                </div>
                <div className={s.buttonContent}>
                  <button className={s.btnAgree}>Agree</button>
                </div>
              </div> */}
            </div>
            {/* full view */}
            {/* <div className={s.content}>
              <div className={s.body}>
                <b>
                  <FormattedMessage {...messages.header} />
                </b>
                <div className={s.body}>
                  This site/app uses cookies. By continuing to browse our site, you
                  you consent to their use. Please visit our cookie policy to find
                  out which cookies we use and why. View Cookie Policy.
                </div>
              </div>
            </div> */}
          </div>
        </div>
      );
    }
    return null;
  }
}

export default withStyles(s)(CookiePolicy);
