/* eslint-disable react/jsx-no-target-blank */
/**
 * Created by methee on 8/4/17.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';

import imageNormal from './btn-app-store.png';
import image2x from './btn-app-store@2x.webp';

class AppStoreButton extends React.Component {
  render() {
    return (
      <div className={this.props.className}>
        <a
          href={this.props.to}
          hrefLang={this.context.intl.locale}
          target="_blank"
        >
          <img
            src={imageNormal}
            srcSet={`${image2x} 2x`}
            alt="App Store"
            width="106px"
            height="38px"
          />
        </a>
      </div>
    );
  }
}

AppStoreButton.propTypes = {
  to: PropTypes.string,
  className: PropTypes.string,
};

AppStoreButton.contextTypes = {
  intl: intlShape,
};

AppStoreButton.defaultProps = {
  to: 'https://itunes.apple.com/app/red-planet-hotels/id979816195',
  className: '',
};

export default AppStoreButton;
