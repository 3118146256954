/* eslint-disable react/static-property-placement */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import s from './RememberedCard.css';

import CDNImage from '../../components/CDNImage';

const path = 'assets/photos/remembered/';

class RememberedCard extends React.Component {
  static propTypes = {
    image: PropTypes.shape({
      normal: PropTypes.string.isRequired,
      add2x: PropTypes.string.isRequired,
    }).isRequired,
    title: PropTypes.shape({
      id: PropTypes.string.isRequired,
      defaultMessage: PropTypes.string.isRequired,
    }).isRequired,
    benefits: PropTypes.shape({
      id: PropTypes.string.isRequired,
      defaultMessage: PropTypes.string.isRequired,
    }).isRequired,
    width: PropTypes.string.isRequired,
    height: PropTypes.string.isRequired,
  };

  render() {
    return (
      <div className={s.root}>
        <CDNImage
          image={{ ...this.props.image }}
          path={path}
          width={this.props.width}
          height={this.props.height}
        />
        <div className={s.message}>
          <FormattedMessage {...this.props.benefits} />
        </div>
        <h2>
          <FormattedMessage {...this.props.title} />
        </h2>
      </div>
    );
  }
}

export default withStyles(s)(RememberedCard);
