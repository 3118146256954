/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { FormattedMessage } from 'react-intl';

import s from './WhyStayWithUsCard.css';

import CDNImage from '../../components/CDNImage';

const assetPath = 'assets/photos/why-stay-with-us/';

class WhyStayWithUsCard extends React.Component {
  render() {
    return (
      <div className={s.root}>
        <CDNImage
          path={assetPath}
          image={{ ...this.props.image }}
          alt={this.props.title.defaultMessage}
          width={this.props.width}
          height={this.props.height}
        />
        <h2>
          <FormattedMessage {...this.props.title} />
        </h2>
      </div>
    );
  }
}

WhyStayWithUsCard.propTypes = {
  image: PropTypes.shape({
    normal: PropTypes.string.isRequired,
    add2x: PropTypes.string.isRequired,
  }).isRequired,
  title: PropTypes.shape({
    id: PropTypes.string.isRequired,
    defaultMessage: PropTypes.string.isRequired,
  }).isRequired,
};

export default withStyles(s)(WhyStayWithUsCard);
