import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { defineMessages, FormattedMessage, intlShape } from 'react-intl';
import cx from 'classnames';

import s from './WhyStayWithUs.css';

import WhyStayWithUsCard from './WhyStayWithUsCard';

const messages = defineMessages({
  whyStayWithUs: {
    id: 'homepage.why_stay_with_us',
    defaultMessage: '##Why Stay With Us##',
    description: 'Why stay with us header',
  },
  greatValue: {
    id: 'homepage.great_value',
    defaultMessage: '##Great Value##',
    description: 'Why stay with us reason: Great Value',
  },
  clean: {
    id: 'homepage.excellent_services',
    defaultMessage: '##Excellent Services##',
    description: 'Why stay with us reason: Excellent Services',
  },
  freeWifi: {
    id: 'homepage.free_wi-fi',
    defaultMessage: '##Free Wi-fi##',
    description: 'Why stay with us reason: Free Wi-fi',
  },
  powerShower: {
    id: 'homepage.power_shower',
    defaultMessage: '##Power Shower##',
    description: 'Why stay with us reason: Power Shower',
  },
  topLocation: {
    id: 'homepage.top_location',
    defaultMessage: '##Top Location##',
    description: 'Why stay with us reason: Top Location',
  },
});

// Used png instead adding changes on the code since file sizes for webp and png are not that different in size
const images = {
  greatValue: {
    normal: 'greatValue.png',
    add2x: 'greatValue@2x.png',
  },
  clean: {
    normal: 'clean.png',
    add2x: 'clean@2x.png',
  },
  freeWifi: {
    normal: 'freeWifi.png',
    add2x: 'freeWifi@2x.png',
  },
  powerShower: {
    normal: 'powerShower.png',
    add2x: 'powerShower@2x.png',
  },
  topLocation: {
    normal: 'topLocation.png',
    add2x: 'topLocation@2x.png',
  },
};

const reasons = [
  'greatValue',
  'clean',
  'freeWifi',
  'powerShower',
  'topLocation',
];

const maxWidth = 900;

class WhyStayWithUs extends React.Component {
  componentDidMount() {
    if (process.env.BROWSER) {
      const middleOfContent = maxWidth / 2;
      const middleOfViewPort = window.innerWidth / 2;
      const leftScrollOffset = middleOfContent - middleOfViewPort;
      if (this.whyStay) {
        this.whyStay.scrollLeft = leftScrollOffset;
      }
    }
  }

  render() {
    const list = [];
    reasons.map(reason => {
      list.push(
        <li key={reason}>
          <WhyStayWithUsCard
            title={{ ...messages[reason] }}
            image={{ ...images[reason] }}
            width="106px"
            height="106px"
          />
        </li>,
      );
      return list;
    });
    return (
      <div className={cx(s.root, s[this.context.intl.locale])}>
        <p>
          <FormattedMessage {...messages.whyStayWithUs} />
        </p>
        <div className={s.redLine} />
        <div
          ref={whyStay => {
            this.whyStay = whyStay;
          }}
          id="whyStayWithUsScrollMask"
          className={s.scrollMask}
        >
          <ul>{list}</ul>
        </div>
      </div>
    );
  }
}

WhyStayWithUs.propTypes = {
  isMobileDevice: PropTypes.bool.isRequired,
};

WhyStayWithUs.contextTypes = {
  intl: intlShape,
};

export default withStyles(s)(WhyStayWithUs);
