/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable camelcase */
import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { FormattedMessage, defineMessages, intlShape } from 'react-intl';
import cx from 'classnames';

import s from './InStayModeFeatures.css';

import InStayModeFeaturesCard from './InStayModeFeaturesCard';
import CDNImage from '../../components/CDNImage';
import AppStoreButton from '../../components/AppStoreButton';
import GooglePlayButton from '../../components/GooglePlayButton';

const messages = defineMessages({
  inStayModeFeatures: {
    id: 'homepage.benefit_in_stay_feature_title',
    defaultMessage: '##In-stay Mode {features}##',
    description:
      'In-Stay mode features section header in homepage. Do not translate or remove {features}. It will get translated in separated key',
  },
  inStayModeFeaturesFeatures: {
    id: 'homepage.benefit_in_stay_feature_title.features',
    defaultMessage: '##Features##',
    description:
      'The "Features" part of In-Stay mode features header in homepage',
  },
  description: {
    id: 'homepage.benefit_in_stay_feature_description',
    defaultMessage:
      'From the minute you book until the day you check-out, our {in_stay} mode is made to make your life easier.',
    description:
      'Description for In-Stay mode features section in homepage. Do not translate or remove {in_stay}. It will get translated in separated key',
  },
  descriptionInstay: {
    id: 'homepage.benefit_in_stay_feature_description.inStay',
    defaultMessage: '##In-Stay##',
    description: 'The "In-Stay" part of In-Stay Mode Features description.',
  },
  chatWithFrontDesk: {
    id: 'homepage.benefit_in_stay_chat_title',
    defaultMessage: '##Chat with front desk##',
    description: 'In-Stay mode feature chat with front desk',
  },
  localAttractions: {
    id: 'homepage.benefit_in_stay_local_attractions_title',
    defaultMessage: '##Local Attractions##',
    description: 'In-Stay mode feature local attractions',
  },
  inHousePhone: {
    id: 'homepage.benefit_in_stay_in_house_phone_title',
    defaultMessage: '##In-House Phone##',
    description: 'In-Stay mode feature in-house phone',
  },
  uberAndGrabServices: {
    id: 'homepage.benefit_in_stay_ride_title',
    defaultMessage: '##Uber & Grab Services##',
    description: 'In-Stay mode feature uber and grab service',
  },
  chatWithFrontDeskDescription: {
    id: 'homepage.benefit_in_stay_chat_description',
    defaultMessage:
      'From the minute you book until the day you check-out, chat with our front desk to let them know you’re running late, get directions to the hotel and more!',
    description: 'In-Stay mode feature chat with front desk description',
  },
  localAttractionsDescription: {
    id: 'homepage.benefit_in_stay_local_attractions_description',
    defaultMessage:
      'Local eats, nightlife, and attractions along with directions and reviews are at your fingertips!',
    description: 'In-Stay mode feature local attractions description',
  },
  inHousePhoneDescription: {
    id: 'homepage.benefit_in_stay_in_house_phone_description',
    defaultMessage:
      'Call the front-desk or your friends by simply dialing their room number, all from within our app!',
    description: 'In-Stay mode feature in-house phone description',
  },
  uberAndGrabServicesDescription: {
    id: 'homepage.benefit_in_stay_ride_description',
    defaultMessage:
      'You can order Uber or Grab directly from our app for a ride back to The hotel !',
    description: 'In-Stay mode feature uber and grab service description',
  },
  getItOnItunes: {
    id: 'homepage.download_google_pay',
    defaultMessage: '##Get it on iTunes##',
    description: 'iTunes link',
  },
  getItOnPlayStore: {
    id: 'homepage.download_app_store',
    defaultMessage: '##Get it on Play Store##',
    description: 'Play Store link',
  },
});

// Used png instead adding changes on the code since file sizes for webp and png are not that different in size
const featureImages = {
  chatWithFrontDesk: {
    normal: 'chatWithFrontDesk.png',
    add2x: 'chatWithFrontDesk@2x.png',
  },
  inHousePhone: {
    normal: 'inHousePhone.png',
    add2x: 'inHousePhone@2x.png',
  },
  localAttractions: {
    normal: 'localAttractions.png',
    add2x: 'localAttractions@2x.png',
  },
  uberAndGrabServices: {
    normal: 'uberAndGrabServices.png',
    add2x: 'uberAndGrabServices@2x.png',
  },
  phoneImage: {
    normal: 'phoneImage.png',
    add2x: 'phoneImage@2x.png',
  },
};

const featureVideos = {
  chatWithFrontDesk: {
    normal:
      'https://cdn.redplanethotels.com/assets/photos/in-stay-mode-features/chatWithFrontDesk.mp4',
    add2x:
      'https://cdn.redplanethotels.com/assets/photos/in-stay-mode-features/chatWithFrontDesk@2x.mp4',
  },
};

// const androidLink =
//   'https://play.google.com/store/apps/details?id=com.redplanet.android';
// const iTuneLink = 'https://itunes.apple.com/app/red-planet-hotels/id979816195';

class InStayModeFeatures extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: '',
    };
  }

  onSelectedFeature = feature => {
    this.setState({ selected: feature });
  };

  onVideoCanPlay = () => {
    // event.target.play();
  };

  render() {
    const { selected } = this.state;
    return (
      <div className={cx(s.root, s[this.context.intl.locale])}>
        <div className={s.container}>
          <h3>
            <FormattedMessage
              {...messages.inStayModeFeatures}
              values={{
                features: (
                  <span className={s.highlight}>
                    <FormattedMessage
                      {...messages.inStayModeFeaturesFeatures}
                    />
                  </span>
                ),
              }}
            />
          </h3>
          <p>
            <FormattedMessage
              {...messages.description}
              values={{
                in_stay: (
                  <span className={s.highlight}>
                    <FormattedMessage {...messages.descriptionInstay} />
                  </span>
                ),
              }}
            />
          </p>
          <table className={s.desktop}>
            <tbody>
              <tr>
                <td>
                  <InStayModeFeaturesCard
                    feature="chatWithFrontDesk"
                    onClick={this.onSelectedFeature}
                    image={{ ...featureImages.chatWithFrontDesk }}
                    title={{ ...messages.chatWithFrontDesk }}
                    description={{
                      ...messages.chatWithFrontDeskDescription,
                    }}
                    width="57px"
                    height="57px"
                  />
                </td>
                <td className={s.phoneImage} rowSpan={2}>
                  <CDNImage
                    image={{ ...featureImages.phoneImage }}
                    path="assets/photos/in-stay-mode-features/"
                    width="205px"
                    height="406px"
                  />
                  {selected && featureVideos[selected] && (
                    <div className={s.videoContainer}>
                      <video
                        onCanPlay={this.onVideoCanPlay}
                        id="feature"
                        width="178"
                        height="312"
                        autoPlay
                        loop
                      >
                        <source
                          src={
                            featureVideos[selected][
                              (window && window.devicePixelRatio) > 1
                                ? 'add2x'
                                : 'normal'
                            ]
                          }
                          type="video/mp4"
                        />
                      </video>
                    </div>
                  )}
                </td>
                <td>
                  <InStayModeFeaturesCard
                    feature="localAttractions"
                    onClick={this.onSelectedFeature}
                    image={{ ...featureImages.localAttractions }}
                    title={{ ...messages.localAttractions }}
                    description={{
                      ...messages.localAttractionsDescription,
                    }}
                    width="52px"
                    height="59px"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <InStayModeFeaturesCard
                    feature="inHousePhone"
                    onClick={this.onSelectedFeature}
                    image={{ ...featureImages.inHousePhone }}
                    title={{ ...messages.inHousePhone }}
                    description={{ ...messages.inHousePhoneDescription }}
                    width="40px"
                    height="48px"
                  />
                </td>
                <td>
                  <InStayModeFeaturesCard
                    feature="uberAndGrabServices"
                    onClick={this.onSelectedFeature}
                    image={{ ...featureImages.uberAndGrabServices }}
                    title={{ ...messages.uberAndGrabServices }}
                    description={{
                      ...messages.uberAndGrabServicesDescription,
                    }}
                    width="75px"
                    height="45px"
                  />
                </td>
              </tr>
              <tr>
                <td className={s.storeButtons} colSpan={3}>
                  <AppStoreButton />
                  <GooglePlayButton />
                </td>
              </tr>
            </tbody>
          </table>
          <table className={s.mobile}>
            <tbody>
              <tr>
                <td rowSpan={4} className={s.phoneImage}>
                  <CDNImage
                    image={{ ...featureImages.phoneImage }}
                    path="assets/photos/in-stay-mode-features/"
                    width="205px"
                    height="406px"
                  />
                  {selected && featureVideos[selected] && (
                    <div className={s.videoContainer}>
                      <video width="178" height="312" autoPlay loop>
                        <source
                          src={
                            featureVideos[selected][
                              (window && window.devicePixelRatio) > 1
                                ? 'add2x'
                                : 'normal'
                            ]
                          }
                          type="video/mp4"
                        />
                      </video>
                    </div>
                  )}
                </td>
                <td>
                  <InStayModeFeaturesCard
                    feature="chatWithFrontDesk"
                    onClick={this.onSelectedFeature}
                    image={{ ...featureImages.chatWithFrontDesk }}
                    title={{ ...messages.chatWithFrontDesk }}
                    description={{
                      ...messages.chatWithFrontDeskDescription,
                    }}
                    width="57px"
                    height="57px"
                  />
                </td>
                <td style={{ width: '5%' }} rowSpan={4} />
              </tr>
              <tr>
                <td>
                  <InStayModeFeaturesCard
                    feature="localAttractions"
                    onClick={this.onSelectedFeature}
                    image={{ ...featureImages.localAttractions }}
                    title={{ ...messages.localAttractions }}
                    description={{
                      ...messages.localAttractionsDescription,
                    }}
                    width="52px"
                    height="59px"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <InStayModeFeaturesCard
                    feature="inHousePhone"
                    onClick={this.onSelectedFeature}
                    image={{ ...featureImages.inHousePhone }}
                    title={{ ...messages.inHousePhone }}
                    description={{ ...messages.inHousePhoneDescription }}
                    width="40px"
                    height="48px"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <InStayModeFeaturesCard
                    feature="uberAndGrabServices"
                    onClick={this.onSelectedFeature}
                    image={{ ...featureImages.uberAndGrabServices }}
                    title={{ ...messages.uberAndGrabServices }}
                    description={{
                      ...messages.uberAndGrabServicesDescription,
                    }}
                    width="75px"
                    height="45px"
                    showBottomLineInMobile={false}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <br />
                </td>
              </tr>
              <tr>
                <td className={s.mobileStoreLinks} colSpan={3}>
                  <AppStoreButton />
                  <GooglePlayButton />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

InStayModeFeatures.contextTypes = {
  intl: intlShape,
};

export default withStyles(s)(InStayModeFeatures);
