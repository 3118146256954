/* eslint-disable react/sort-comp */
/* eslint-disable react/static-property-placement */
import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { FormattedMessage, intlShape } from 'react-intl';
import cx from 'classnames';

import CDNImage from '../../components/CDNImage';

import s from './InStayModeFeaturesCard.css';

class InStayModeFeaturesCard extends React.Component {
  static propTypes = {
    feature: PropTypes.string.isRequired,
    image: PropTypes.shape({
      normal: PropTypes.string.isRequired,
      add2x: PropTypes.string.isRequired,
    }).isRequired,
    title: PropTypes.shape({
      id: PropTypes.string.isRequired,
      defaultMessage: PropTypes.string.isRequired,
    }).isRequired,
    description: PropTypes.shape({
      id: PropTypes.string.isRequired,
      defaultMessage: PropTypes.string.isRequired,
    }).isRequired,
    showBottomLineInMobile: PropTypes.bool,
    onClick: PropTypes.func,
    width: PropTypes.string.isRequired,
    height: PropTypes.string.isRequired,
  };

  static defaultProps = {
    showBottomLineInMobile: true,
    onClick: () => {},
  };

  static contextTypes = {
    intl: intlShape,
  };

  onClick = () => {
    this.props.onClick(this.props.feature);
  };

  render() {
    const { showBottomLineInMobile } = this.props;
    return (
      <div
        className={cx(
          s.root,
          showBottomLineInMobile && s.mobileBottomLine,
          s[this.context.intl.locale],
        )}
      >
        <CDNImage
          image={{ ...this.props.image }}
          path="assets/photos/in-stay-mode-features/"
          width={this.props.width}
          height={this.props.height}
        />
        <h2>
          <FormattedMessage {...this.props.title} />
        </h2>
        <p>
          <FormattedMessage {...this.props.description} />
        </p>
      </div>
    );
  }
}

export default withStyles(s)(InStayModeFeaturesCard);
