/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { defineMessages, FormattedMessage, intlShape } from 'react-intl';
import { compose, graphql } from 'react-apollo';
import cx from 'classnames';

import s from './OurGuestsSay.css';
import query from './our-guest-say.graphql';

import TruncatableText from '../../components/TruncatableText';

const messages = defineMessages({
  ourGuestsSay: {
    id: 'homepage.our_guests_say',
    defaultMessage: '##Our Guests Say##',
    description: 'Our Guests Say header',
  },
  fullName: {
    id: 'hotel.full_name',
    defaultMessage: '##Red Planet {hotelName}##',
    description: 'Hotel full name. Do not translate or remove {hotelName}',
  },
});

const tripAdvisorUrl =
  'https://www.tripadvisor.com/img/cdsi/img2/ratings/traveler/';
const tripAdvisorImage = '-50271-5.svg';

class OurGuestsSay extends React.Component {
  render() {
    const {
      data: { loading, review },
      isMobileDevice,
    } = this.props;
    if (loading || !review) {
      return null;
    }
    const { name, user_contents } = review;
    if (user_contents === null) {
      return null;
    }
    const { reviews } = user_contents;
    if (reviews === null) {
      return null;
    }
    const r = reviews[Math.floor(reviews.length * Math.random())];
    const {
      title,
      message,
      rating,
      review_url,
      user: { avatar_large_url },
    } = r;
    const tripadvisor = `${tripAdvisorUrl}${rating.toPrecision(
      2,
    )}${tripAdvisorImage}`;
    return (
      <div className={cx(s.root, s[this.context.intl.locale])}>
        <h3>
          <FormattedMessage {...messages.ourGuestsSay} />
        </h3>
        <div className={s.whiteLine} />
        <div className={s.like} />
        <div className={s.container}>
          <div className={s.hotelName}>
            <FormattedMessage
              {...messages.fullName}
              values={{
                hotelName: <span className={s.highlight}>{name}</span>,
              }}
            />
          </div>
          <div>
            <div className={s.colLeft}>
              <img
                src={avatar_large_url}
                alt="avatar"
                width="150px"
                height="150px"
              />
            </div>
            <div className={s.colRight}>
              <div className={s.rating}>
                <img src={tripadvisor} alt="TripAdvisor Rating" />
              </div>
              <a
                href={review_url}
                hrefLang={this.context.intl.locale}
                target="__blank"
              >
                <h2>{`"${title}"`}</h2>
              </a>
              <div className={s.message}>
                {isMobileDevice ? (
                  <TruncatableText text={message} lines={5} lineHeight={24} />
                ) : (
                  <TruncatableText text={message} lines={4} lineHeight={27} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

OurGuestsSay.propTypes = {
  data: PropTypes.shape({
    loading: PropTypes.bool,
    review: PropTypes.shape({
      name: PropTypes.string,
      user_contents: PropTypes.shape({
        reviews: PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string,
            message: PropTypes.string,
            review_url: PropTypes.string,
            user: PropTypes.shape({
              username: PropTypes.string,
              avatar_large_url: PropTypes.string,
            }),
          }),
        ),
      }),
    }),
  }).isRequired,
  isMobileDevice: PropTypes.bool.isRequired,
};

OurGuestsSay.contextTypes = {
  intl: intlShape,
};

export default compose(graphql(query), withStyles(s))(OurGuestsSay);
