/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import moment from 'moment';
import cx from 'classnames';
import { intlShape } from 'react-intl';

import Link from '../Link/Link';
import s from './PromotionsPane.css';

const placeholderPromotions = {
  allHotels: '/search/?country=10',
  philippinesHotels: '/search/?country=13',
  thailandHotels: '/search/?country=3',
  // indonesiaHotels: '/search/?country=10',
  // japanHotels: '/search/?country=11',
};

const chrome = 'Chrome';

class PromotionsPane extends React.Component {
  render() {
    const { items, browserName } = this.props;

    const getPromotionLink = promotion => {
      let url = '';
      if (promotion.code) {
        if (promotion.url) {
          url = promotion.url;
        } else if (placeholderPromotions[promotion.code]) {
          const checkIn = moment();
          const checkOut = checkIn.clone().add(1, 'd');
          url = `${
            placeholderPromotions[promotion.code]
          }&check_in=${checkIn.format(
            'YYYY-MM-DD',
          )}&check_out=${checkOut.format(
            'YYYY-MM-DD',
          )}&rooms=1&adults=2&children=0`;
        } else {
          url = `/promotion/${promotion.code}`;
        }
      }
      return url;
    };

    const ChromeRender = () => {
      if (browserName !== chrome) return null;
      return (
        <section className={s.test}>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '35%' }}>
              <a href="/red-app">
                <div className={s.backgClrIndo} />
                {/* <img
                  style={{
                    width: '100%',
                    height: '416px',
                  }}
                  src={items[0].image_url}
                  alt=""
                /> */}
                <div
                  style={{
                    width: '100%',
                    height: '416px',
                    backgroundImage: `url('${items[0].image_url}')`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: '50% 50%',
                  }}
                />
                <div className={cx(s.leftTextBox1)}>
                  <h3 className={s.fontLemon}>
                    {items[0].description.split('\n').map(line =>
                      line === 'ALWAYS' ? (
                        <div className={s.redText} key={line}>
                          {line}
                        </div>
                      ) : (
                        <div className={s.whiteText} key={line}>
                          {line}
                        </div>
                      ),
                    )}
                  </h3>
                  <p className={s.whiteText}>
                    {items[0].secondary_description}
                  </p>
                </div>
              </a>
            </div>

            <div style={{ width: '65%' }}>
              <Link to={getPromotionLink(items[9])}>
                <div className={s.backgClrPH} />
                {/* <img
                  style={{ width: '100%', height: '416px' }}
                  src={items[4].image_url}
                  alt=""
                /> */}
                <div
                  style={{
                    width: '100%',
                    height: '416px',
                    backgroundImage: `url('${items[4].image_url}')`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: '50% 50%',
                  }}
                />
                <div className={s.rightTextBox2}>
                  <h3 className={s.bigger}>
                    {items[9].description.split('\n').map(line =>
                      line === 'PHILIPPINES' ? (
                        <div className={s.redText} key={line}>
                          {line}
                        </div>
                      ) : (
                        <div className={s.whiteText} key={line}>
                          {line}
                        </div>
                      ),
                    )}
                  </h3>
                  <p>{items[9].secondary_description}</p>
                </div>
              </Link>
            </div>
          </div>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '35%' }}>
              <Link to={getPromotionLink(items[2])}>
                <div className={s.backgClrBerna} />
                {/* <img
                  style={{ width: '100%', height: '291px' }}
                  src={items[2].image_url}
                  alt=""
                /> */}
                <div
                  style={{
                    width: '100%',
                    height: '291px',
                    backgroundImage: `url('${items[2].image_url}')`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: '50% 50%',
                  }}
                />
                <div className={cx(s.leftTextBox2)}>
                  <h3 className={cx(s.whiteText, s.fontLemon)}>
                    {items[2].description.split('\n').map(line => (
                      <div key={line}>
                        <span className={s.redText}>{line[0]}</span>
                        <span className={s.whiteText}>{line[1]}</span>
                        <span className={s.whiteText}>{line[2]}</span>
                        <span className={s.whiteText}>{line[3]}</span>
                        <span className={s.whiteText}>{line[4]}</span>
                        <span className={s.whiteText}>{line[5]}</span>
                        <span className={s.whiteText}>{line[6]}</span>
                        <span className={s.whiteText}>{line[7]}</span>
                        <span className={s.whiteText}>{line[8]}</span>
                      </div>
                    ))}
                  </h3>
                  <p>{items[2].secondary_description}</p>
                </div>
              </Link>
              <Link to={getPromotionLink(items[3])}>
                <div className={s.backgClrRed} />
                {/* <img
                  style={{ width: '100%', height: '291px' }}
                  src={items[3].image_url}
                  alt=""
                /> */}
                <div
                  style={{
                    width: '100%',
                    height: '291px',
                    backgroundImage: `url('${items[3].image_url}')`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: '50% 50%',
                  }}
                />
                <div className={cx(s.leftTextBox3)}>
                  <h3 className={s.fontLemon}>
                    {items[8].description.split('\n').map(line =>
                      line === 'RED' ? (
                        <div className={s.whiteText} key={line}>
                          {line}
                        </div>
                      ) : (
                        <div className={s.blackText} key={line}>
                          {line}
                        </div>
                      ),
                    )}
                  </h3>
                  <p>{items[8].secondary_description}</p>
                </div>
              </Link>
            </div>

            <div style={{ width: '65%' }}>
              <Link to={getPromotionLink(items[1])}>
                <div className={s.backgClrThai} />
                {/* <img
                  style={{ width: '100%', height: '582px' }}
                  src={items[1].image_url}
                  alt=""
                /> */}
                <div
                  style={{
                    width: '100%',
                    height: '582px',
                    backgroundImage: `url('${items[1].image_url}')`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: '50% 50%',
                  }}
                />
                <div className={s.rightTextBox3}>
                  <h3 className={s.bigger}>
                    {items[6].description.split('\n').map(line =>
                      line === 'THAILAND' ? (
                        <div className={s.redText} key={line}>
                          {line}
                        </div>
                      ) : (
                        <div className={s.whiteText} key={line}>
                          {line}
                        </div>
                      ),
                    )}
                  </h3>
                  <p>{items[6].secondary_description}</p>
                </div>
              </Link>
            </div>
          </div>
        </section>
      );
    };

    return (
      // there are issues when implementing 2 tables (tbody) for Safari as images are not rendering properly and
      // that is why you see another section on the code and not another tbody again. To check for mobile
      // size just used media queries of CSS for it.
      <table className={cx(s.root, s[this.context.intl.locale])}>
        <tbody className={s.test}>
          <tr>
            <td
              className={s.topRow}
              colSpan={2}
              style={{
                width: '35%',
                backgroundImage: `url('${items[5].image_url}')`,
              }}
            >
              <a href="https://info.redplanethotels.com/app">
                <div className={cx(s.leftTextBox, s.darkBackg)}>
                  <h3 className={s.fontLemon}>
                    {items[5].description
                      .split('\n')
                      .map(line =>
                        line === 'ALWAYS' ? (
                          <div className={s.redText}>{line}</div>
                        ) : (
                          <div className={s.whiteText}>{line}</div>
                        ),
                      )}
                  </h3>
                  <p className={s.whiteText}>
                    {items[5].secondary_description}
                  </p>
                </div>
              </a>
            </td>
            <td
              className={s.topRow}
              style={{
                width: '65%',
                backgroundImage: `url('${items[9].image_url}')`,
              }}
            >
              <Link to={getPromotionLink(items[9])}>
                <div className={s.rightTextBox}>
                  <h3 className={s.bigger}>
                    {items[9].description
                      .split('\n')
                      .map(line =>
                        line === 'PHILIPPINES' ? (
                          <div className={s.redText}>{line}</div>
                        ) : (
                          <div className={s.whiteText}>{line}</div>
                        ),
                      )}
                  </h3>
                  <p>{items[9].secondary_description}</p>
                </div>
              </Link>
            </td>
          </tr>
          <tr>
            <td
              className={s.bottomRow}
              style={{
                width: '35%',
                backgroundImage: `url('${items[7].image_url}')`,
              }}
            >
              <Link to={getPromotionLink(items[7])}>
                <div className={cx(s.leftTextBox, s.darkBackg)}>
                  <h3 className={cx(s.whiteText, s.fontLemon)}>
                    {items[7].description.split('\n').map(line => (
                      <div>
                        <span className={s.redText}>{line[0]}</span>
                        <span className={s.whiteText}>{line[1]}</span>
                        <span className={s.whiteText}>{line[2]}</span>
                        <span className={s.whiteText}>{line[3]}</span>
                        <span className={s.whiteText}>{line[4]}</span>
                        <span className={s.whiteText}>{line[5]}</span>
                        <span className={s.whiteText}>{line[6]}</span>
                        <span className={s.whiteText}>{line[7]}</span>
                        <span className={s.whiteText}>{line[8]}</span>
                      </div>
                    ))}
                  </h3>
                  <p>{items[7].secondary_description}</p>
                </div>
              </Link>
            </td>
            <td
              colSpan={2}
              rowSpan={2}
              style={{
                width: '65%',
                backgroundImage: `url('${items[6].image_url}')`,
              }}
            >
              <Link to={getPromotionLink(items[6])}>
                <div className={s.rightTextBox}>
                  <h3>
                    {items[6].description
                      .split('\n')
                      .map(line =>
                        line === 'THAILAND' ? (
                          <div className={s.redText}>{line}</div>
                        ) : (
                          <div className={s.whiteText}>{line}</div>
                        ),
                      )}
                  </h3>
                  <p>{items[6].secondary_description}</p>
                </div>
              </Link>
            </td>
          </tr>
          <tr>
            <td
              className={s.bottomRow}
              style={{
                width: '35%',
                backgroundImage: `url('${items[8].image_url}')`,
              }}
            >
              <Link to={getPromotionLink(items[8])}>
                <div className={cx(s.leftTextBox, s.lightBackg)}>
                  <h3 className={s.fontLemon}>
                    {items[8].description
                      .split('\n')
                      .map(line =>
                        line === 'RED' ? (
                          <div className={s.whiteText}>{line}</div>
                        ) : (
                          <div className={s.blackText}>{line}</div>
                        ),
                      )}
                  </h3>
                  <p>{items[8].secondary_description}</p>
                </div>
              </Link>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}

PromotionsPane.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string.isRequired,
      secondary_description: PropTypes.string.isRequired,
      image_url: PropTypes.string.isRequired,
    }),
  ).isRequired,
  browserName: PropTypes.string.isRequired,
};

PromotionsPane.contextTypes = {
  intl: intlShape,
};

export default withStyles(s)(PromotionsPane);
