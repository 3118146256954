// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ":root {\n\n  /*\n   * Typography\n   * ======================================================================== */\n\n  /*\n   * Layout\n   * ======================================================================== */\n\n  /*\n   * Media queries breakpoints\n   * ======================================================================== */ /* Extra small screen / phone */ /* Small screen / tablet */ /* Medium screen / desktop */ /* Large screen / wide desktop */\n\n  font-size: 16px;\n  color: #000;\n}\n\n.WhyStayWithUsCard-root {\n  width: 100%;\n  text-align: center;\n  padding-top: 32px;\n  /* Safari */\n  -webkit-transition: padding-top 0.2s; \n  -o-transition: padding-top 0.2s; \n  transition: padding-top 0.2s;\n}\n\n.WhyStayWithUsCard-root img {\n  width: 106px;\n   /* Safari */\n  -webkit-transition:  width 0.2s, height 0.2s;\n  -o-transition: width 0.2s, height 0.2s;\n  transition: width 0.2s, height 0.2s;\n}\n\n.WhyStayWithUsCard-root:hover {\n  padding-top: 0;\n}\n\n.WhyStayWithUsCard-root:hover img {\n  width: 160px;\n  height: 160px;\n}\n\n.WhyStayWithUsCard-root h2 {\n  text-transform: uppercase;\n}", ""]);
// Exports
exports.locals = {
	"root": "WhyStayWithUsCard-root"
};
module.exports = exports;
