/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { graphql, compose } from 'react-apollo';

import query from './home.graphql';
import s from './Home.css';

import CarouselImages from '../../components/CarouselImages';
import PromotionsPane from '../../components/PromotionsPane/PromotionsPane';
import BookingEngine from '../../components/BookingEngine';
import CookiePolicy from '../../components/CookiePolicy';
import WhyStayWithUs from './WhyStayWithUs';
import OurGuestsSay from './OurGuestsSay';
import Remembered from './Remembered';
import InStayModeFeatures from './InStayModeFeatures';

const maxMobileWidth = 992;

const windowGlobal = typeof window !== 'undefined' && window;

class Home extends React.Component {
  bindOnWindowResize = null;

  constructor(props) {
    super(props);
    this.state = {
      isMobileDevice: false,
    };
  }

  componentDidMount() {
    if (process.env.BROWSER) {
      this.onWindowResize();
      this.bindOnWindowResize = this.onWindowResize.bind(this);
      window.addEventListener('resize', this.bindOnWindowResize);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.bindOnWindowResize);
  }

  onWindowResize = () => {
    this.setState({
      isMobileDevice: window.innerWidth < maxMobileWidth,
    });
  };

  render() {
    const { isMobileDevice } = this.state;

    const {
      data: { loading, coverImages, promotions },
      browserName,
    } = this.props;
    let allowCookie = true;
    if (windowGlobal.localStorage) {
      allowCookie = windowGlobal.localStorage.getItem('allow_cookie');
    }
    return (
      <div className={s.root}>
        {loading ? '' : <CarouselImages images={coverImages} />}

        <BookingEngine
          marginTop={isMobileDevice ? 0 : -1}
          clearBookingBeforeSearch
        />
        <div style={{ marginTop: '-7px' }}>
          {isMobileDevice ? (
            ''
          ) : (
            <PromotionsPane items={promotions} browserName={browserName} />
          )}
          <WhyStayWithUs isMobileDevice={isMobileDevice} />
          <OurGuestsSay isMobileDevice={isMobileDevice} />
          <Remembered isMobileDevice={isMobileDevice} />
          <InStayModeFeatures />
          {!allowCookie && <CookiePolicy />}
        </div>
      </div>
    );
  }
}

Home.propTypes = {
  data: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    coverImages: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        image: PropTypes.string.isRequired,
      }),
    ),
    promotions: PropTypes.arrayOf(
      PropTypes.shape({
        image_url: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
  browserName: PropTypes.string.isRequired,
};

export default compose(withStyles(s), graphql(query))(Home);
