/* eslint-disable react/jsx-no-target-blank */
/**
 * Created by methee on 8/4/17.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';

import imageNormal from './btn-google-play.png';
import image2x from './btn-google-play@2x.png';

class GooglePlayButton extends React.Component {
  render() {
    return (
      <div className={this.props.className}>
        <a
          href={this.props.to}
          hrefLang={this.context.intl.locale}
          target="_blank"
        >
          <img
            src={imageNormal}
            srcSet={`${image2x} 2x`}
            alt="App Store"
            width="106px"
            height="38px"
          />
        </a>
      </div>
    );
  }
}

GooglePlayButton.propTypes = {
  to: PropTypes.string,
  className: PropTypes.string,
};

GooglePlayButton.defaultProps = {
  to:
    'https://play.google.com/store/apps/details?id=com.redplanethotels.android',
  className: '',
};

GooglePlayButton.contextTypes = {
  intl: intlShape,
};

export default GooglePlayButton;
